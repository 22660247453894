<template>
  <div class="home text-center">
    <router-link class="float-left text-dark" style="font-size:1.5em;" to="/"><b-icon-chevron-left></b-icon-chevron-left></router-link>
    <br />
    <h2 class="text-secondary mt-3">Enter PIN</h2>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">PIN</span>
      </div>
      <input v-model="pw" type="password" class="form-control" inputmode="numeric" />
      <div class="input-group-append">
        <button @click="elevate" class="btn btn-outline-secondary" type="button">Login</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import rootPath from '../rootPath'

export default {
  name: 'Elevate',
  components: {},
  data() {
    return {
      pw: ''
    }
  },
  computed: { ...mapState(['token']) },
  methods: {
    async elevate() {
      await fetch(rootPath + 'api/elevate.php?t=' + this.token, {
        method: 'POST',
        body: JSON.stringify({
          pw: this.pw
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      })
    }
  },
  watch: {}
}
</script>

<style scoped></style>
